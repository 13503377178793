$blue: #7dbcd2;
$darkBlue: #0b8aad;
$white : #FFF;
$darkGrey: #b9b9b9;
$lightGrey: #e7e7e7;
$smallText: 12px;
$mediumText: 14px;

* {
  font-size: $mediumText;
}

.device-message {
    margin-top: 99px;
    white-space: pre-wrap;
}

.no-highlight {
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
   -ms-user-select: none;
   user-select: none;
}

.popup {
  z-index: 10;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  padding: 15px;
  position: absolute;
  width: 40vw;
  height: 30vh;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 5px 5px 5px grey;
  right: 35vw;
  top: 33vh;
  flex-wrap:wrap;
}

.status-message {
    margin-top: 30px;
    white-space: pre-wrap;
    height: calc(75vh - 120px);
    overflow-y: scroll;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.pair-device-button {
  margin-top: -5vh;
  position: absolute;
  cursor: pointer;
  text-align: center;
  padding: 10px 10px;
  font-size: 44px;
  font-weight: bold;
  color: $darkBlue;
  border: 2px solid $darkBlue;
  border-radius: 10px;
}

.pair-device-button:hover {
  background-color: $blue;
  color: $white;
  border: none;
}

.settings-popup {
  width: 300px;
  padding: 29px;
  border: 1px solid $blue;
  border-radius: 58px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  box-shadow: 5px 5px #80808029;
  position: absolute;
  right: 6vw;
  top: 15px;
}

.settings-popup .button {
  width: 70%;
}

.button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: $darkBlue;
  color: white;
  border-radius: 15px;
  margin-top: 15px;
  font-size: 22px
}

.button:hover {
  background-color: $blue;
}

.option {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  width: 59%;
}

.load-csv {
  width: 55%;
  font-size: 25px;
}
