body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-size: 14px;
}

.device-message {
  margin-top: 99px;
  white-space: pre-wrap;
}

.no-highlight {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

.popup {
  z-index: 10;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  padding: 15px;
  position: absolute;
  width: 40vw;
  height: 30vh;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 5px 5px 5px grey;
  right: 35vw;
  top: 33vh;
  flex-wrap: wrap;
}

.status-message {
  margin-top: 30px;
  white-space: pre-wrap;
  height: calc(75vh - 120px);
  overflow-y: scroll;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pair-device-button {
  margin-top: -5vh;
  position: absolute;
  cursor: pointer;
  text-align: center;
  padding: 10px 10px;
  font-size: 44px;
  font-weight: bold;
  color: #0b8aad;
  border: 2px solid #0b8aad;
  border-radius: 10px;
}

.pair-device-button:hover {
  background-color: #7dbcd2;
  color: #FFF;
  border: none;
}

.settings-popup {
  width: 300px;
  padding: 29px;
  border: 1px solid #7dbcd2;
  border-radius: 58px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  box-shadow: 5px 5px rgba(128, 128, 128, 0.1607843137);
  position: absolute;
  right: 6vw;
  top: 15px;
}

.settings-popup .button {
  width: 70%;
}

.button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #0b8aad;
  color: white;
  border-radius: 15px;
  margin-top: 15px;
  font-size: 22px;
}

.button:hover {
  background-color: #7dbcd2;
}

.option {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  width: 59%;
}

.load-csv {
  width: 55%;
  font-size: 25px;
}
